import React from 'react'
import { graphql } from 'gatsby';
import loadable from "@loadable/component";
import Layout from '../components/layout'
import Seo from '../components/seo';
import { PageLinks } from '../common/site/page-static-links';

const Banner = loadable(() => import("../components/Banner/Banner"));
const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const OfficeDetailLanding = loadable(() => import("../components/OfficeDetailLanding/OfficeDetailLanding"));
const PropertyDetailsMap = loadable(() => import("../components/PropertyDetailsMap/PropertyDetailsMap"));
const NewsLetterModule = loadable(() => import("../components/NewsLetterModule/NewsLetterModule"));
const TeamSlider = loadable(() => import("../components/TeamSlider/TeamSlider"));
const OfficeDetailsTemplate = ({ data }) => {
    const OfficesData = data?.strapiOffice

    const officeInfo = {
        phone: OfficesData.phone,
        email: OfficesData.email,
        address: OfficesData.address
    }

    const bannerCTAS = {
        cta_1_title: "Speak to us",
        cta_1_link: {
            link_type: "internal",
            slug: PageLinks.team,
            title: "Speak to us",
        },
        cta_2_title: "book a valuation",
        cta_2_link: {
            link_type: "internal",
            slug: PageLinks.valuation,
            title: "book a valuation",
        }
    }

    let breadcrumData;

    if (PageLinks?.office_parent_label) {
        breadcrumData = { parentlabel: PageLinks.office_parent_label, parentlink: PageLinks.office_parent, subparentlabel: PageLinks.office_label, subparentlink: PageLinks.contact, title: OfficesData.title }
    } else {
        breadcrumData = { parentlabel: PageLinks.office_label, parentlink: PageLinks.contact, title: OfficesData.title }
    }

    return (
        <Layout popularSearch={"Generic pages"}>
            <div className="layout-padding-top"></div>
            <BreadcrumbModule {...breadcrumData} />

            <div className='layout-grey-bg-theme'>

                <Banner
                    officeInfo={officeInfo}
                    image={OfficesData.image}
                    title={OfficesData.title}
                    id={OfficesData.strapi_id}
                    imagetransforms={OfficesData.imagetransforms}
                    {...OfficesData}
                    tag="landing-banner"
                    showTextOnMobile
                    show_review
                    cta_1_title={bannerCTAS.cta_1_title}
                    cta_1_link={bannerCTAS.cta_1_link}
                    cta_2_title={bannerCTAS.cta_2_title}
                    cta_2_link={bannerCTAS.cta_2_link}
                />


                <OfficeDetailLanding OfficesData={OfficesData} />

                <PropertyDetailsMap
                    lat={OfficesData.latitude}
                    lng={OfficesData.longitude}
                />

                <TeamSlider
                    bgColor="layout-grey-bg-theme"
                    select_team={OfficesData.select_team}
                    title={OfficesData.team_heading}
                    content={{ data: { content: OfficesData?.team_content?.data.team_content } }}
                    textdark="text-dark"
                />

                <NewsLetterModule />
            </div>
        </Layout>
    )
}


export const Head = ({ data }) => {
    const OfficesData = data?.strapiOffice
    const metaDescription = `Our Estate agents in ${OfficesData.title} offer the best property advice in Selling, Buying, Letting and Renting. Contact us to get assistance.`

    var ldJson = {
        "@context": "https://schema.org",
        "@type": "RealEstateAgent",
        "url": process.env.GATSBY_SITE_URL + `/` + PageLinks.contact,
        "name": process.env.GATSBY_SITE_NAME + ` in ` + OfficesData?.title,
        "alternateName": process.env.GATSBY_SITE_NAME,
        "logo": process.env.GATSBY_SITE_URL + `/images/logo.png`,
        "description": OfficesData?.title,
        "address": {
            "@type": "PostalAddress",
            "streetAddress": OfficesData?.address,
            "addressLocality": OfficesData?.address,
            "addressRegion": OfficesData?.address,
            "addressCountry": OfficesData?.address
        },
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": OfficesData?.latitude,
            "longitude": OfficesData?.longitude
        },
        "email": OfficesData?.email,
        "telephone": OfficesData?.phone,
        "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "Sales"
        }
    };

    return (
        <Seo title={OfficesData.title} description={metaDescription}>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
            />
        </Seo>
    )
}

export default OfficeDetailsTemplate

export const query = graphql`
query ($page_id: Int) {
    strapiOffice(strapi_id: {eq: $page_id}) {
        ...OfficeFragment
        banner_content {
            data {
              banner_content
            }
        }
        about {
            data {
                about
            }
        }
        latitude
        longitude
        opening_hours {
            data {
                opening_hours
            }
        }
        team_heading
        team_content {
            data {
                team_content
            }
        }
        select_team {
            ...TeamFragment
        }
    }
}`